import { Text } from "@amzn/stencil-react-components/text";
import React, { FC } from "react";

import { getString } from "../../uistringlabels/uiStringUtils";

export interface PriceTextProps {
  ammount?: number;
  currency?: string;
}

export const PriceText: FC<PriceTextProps> = ({ ammount, currency }: PriceTextProps) => {
  let priceToDisplay = "";
  if (ammount == undefined) {
    priceToDisplay = getString("pricing.noPrice");
  } else if (ammount == 0) {
    priceToDisplay = getString("pricing.free");
  } else {
    priceToDisplay = `${currency} ${new Intl.NumberFormat("en-US", { style: "currency", currency: currency }).format(
      ammount?.valueOf()
    )}`;
  }
  return (
    <Text fontSize="T400" fontWeight="regular">
      {priceToDisplay}
    </Text>
  );
};
