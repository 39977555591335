import { DeliveryAddress, Order, UpdateOneOrderStatus } from "@amzn/ito-client";
import jp from "jsonpath";
import { useEffect, useState } from "react";

import useInput from "../../../hooks/useInput";
import { Address, AmazonianInformation } from "../../../lib/services/user-information-service";

export interface AddressFormFields {
  fullName: string;
  country: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  citySubdivision1: string;
  postalCode: string;
  countryCode: string;
  phoneNumber: string;
}

export const useChangeAddressForm = (info?: AmazonianInformation) => {
  // Fill initial values
  const { formValues, handleInputChange, setFormValuesBulk } = useInput<AddressFormFields>({
    fullName: "",
    country: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    citySubdivision1: "",
    postalCode: "",
    countryCode: "",
    phoneNumber: "",
  });

  const setAddressInformation = (info: AmazonianInformation) => {
    const address = info.basicInfo.addresses.home;
    const hasPhoneNumber = info.basicInfo.phones?.length && info.basicInfo.phones?.length > 0;
    setFormValuesBulk({
      fullName: `${info.basicInfo.firstName} ${info.basicInfo.lastName}`,
      country: address.country ?? "",
      addressLine1: address.addressLineData[0] ?? "",
      addressLine2: address.addressLineData[1] ?? "",
      city: address.city ?? "",
      citySubdivision1: address.citySubdivision1 ?? "",
      postalCode: address.postalCode ?? "",
      countryCode: "",
      phoneNumber: hasPhoneNumber ? `${info.basicInfo.phones![0].number}` : "",
    });
  };

  return {
    formValues,
    handleInputChange,
    setAddressInformation,
  };
};

// function getValueByPath<T>(obj: T, path: string): any {
//     const value = jp.value(obj, path as string);
//     return value ?? undefined;
// }

// /**
//  * Based on a path like "field.nested1.nested2" returns an object like { field: { nested1: { nested2: "value" } } }
//  */
// export function setNestedProperty(obj: any, path: string, value: any): void {
//     const keys = path.split('.');
//     keys.reduce((acc, curr, index) => {
//         if (index === keys.length - 1) {
//             acc[curr] = value;
//         } else {
//             if (!acc[curr]) {
//                 acc[curr] = {};
//             }
//             return acc[curr];
//         }
//         return acc;
//     }, obj);
// }
