import { DeliveryAddress } from "@amzn/ito-client";

import useInput from "../../../hooks/useInput";
import { CatalogItemVersion } from "./CheckoutPageSHOP";
import { AddressFormFields } from "./useChangeAddressForm";

export interface CheckoutFormFields {
  catalogItemTxId: CatalogItemVersion;

  businessJustification: string;
  costCenter: string;
  managerConfirmationCheckbox: boolean;

  addressDetails: AddressFormFields | undefined;
  deliveryInstructions: string;
  shippingDetailsConfirmationCheckbox: boolean;

  policyConfirmationCheckbox: boolean;
}

export const useCheckoutForm = (catalogItemId: CatalogItemVersion) => {
  // Fill initial values
  const { formValues, handleInputChange, setFormValuesBulk } = useInput<CheckoutFormFields>({
    catalogItemTxId: catalogItemId,
    businessJustification: "",
    costCenter: "",
    managerConfirmationCheckbox: false,

    addressDetails: undefined,
    deliveryInstructions: "",
    shippingDetailsConfirmationCheckbox: false,

    policyConfirmationCheckbox: false,
  });

  const createDeliveryAddress = (addressForm: AddressFormFields): DeliveryAddress => {
    const address: DeliveryAddress = {
      fullName: addressForm.fullName,
      addressLineData: [addressForm.addressLine1, addressForm.addressLine2],
      country: addressForm.country,
      region: undefined,
      city: addressForm.city,
      citySubdivision1: addressForm.citySubdivision1,
      postalCode: addressForm.postalCode,
      phoneNumber: `${addressForm.countryCode} ${addressForm.phoneNumber}`,
      deliveryInstructions: formValues.deliveryInstructions,
    };
    return address;
  };

  return {
    formValues,
    handleInputChange,
    createDeliveryAddress,
  };
};
