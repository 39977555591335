import "./ordernow.scss";

import { Button, ButtonIconPosition, ButtonVariant } from "@amzn/stencil-react-components/button";
import { IconExternalLink, IconInformation } from "@amzn/stencil-react-components/icons";
import { IconQuestionCircle } from "@amzn/stencil-react-components/icons";
import { Col, Row, View } from "@amzn/stencil-react-components/layout";
import { Link } from "@amzn/stencil-react-components/link";
import { Text } from "@amzn/stencil-react-components/text";
import { withTooltip } from "@amzn/stencil-react-components/tooltip";
import React from "react";
import { FunctionComponent } from "react";

import { ItemStatus } from "../../types/catalogical-type";
import { getString } from "../../uistringlabels/uiStringUtils";
import { PriceText } from "../PriceText";

export interface OrderNowProps {
  orderRedirectUrl: string | undefined;
  moreDetailsUrl: string | undefined;
  status: string | undefined;
  amount: number | undefined;
  currency: string | undefined;
  orderNowHandler: () => void;
}

export const OrderNow: FunctionComponent<OrderNowProps> = ({
  orderNowHandler,
  orderRedirectUrl,
  moreDetailsUrl,
  status,
  amount,
  currency,
}: OrderNowProps) => {
  /* istanbul ignore next */
  const goToOrderRedirect = () => {
    if (orderRedirectUrl) window.location.assign(orderRedirectUrl);
  };

  /* istanbul ignore next */
  const requestSecurityReviewHandler = () => {
    if (moreDetailsUrl) window.location.assign(moreDetailsUrl);
  };

  if (status == ItemStatus.sanctioned && orderRedirectUrl != "" && orderRedirectUrl != undefined) {
    return (
      <View className="order-now">
        <Row>
          <Col alignItems="center">
            <Button
              icon={<IconExternalLink aria-hidden={true} />}
              iconPosition={ButtonIconPosition.Trailing}
              onClick={goToOrderRedirect}
              id="orderNowLink"
              data-cy="learn-more-button"
            >
              {getString("ordering.learnMore")}
            </Button>
          </Col>
        </Row>
      </View>
    );
  }

  if (status == ItemStatus.sanctioned) {
    const orderButtonText = getString("ordering.orderNow");
    return (
      <View className="order-now">
        <Row>
          <Col alignItems="center">
            <PriceText ammount={amount} currency={currency} />
            <Button variant={ButtonVariant.Primary} onClick={orderNowHandler} data-cy="order-now-button">
              {orderButtonText}
            </Button>
          </Col>
        </Row>
      </View>
    );
  }

  if (status == ItemStatus.unsanctioned) {
    return (
      <View className="order-now">
        <Row>
          <Col alignItems="center">
            <Button
              icon={<IconExternalLink aria-hidden={true} />}
              iconPosition={ButtonIconPosition.Trailing}
              onClick={requestSecurityReviewHandler}
              data-cy="request-review-button"
            >
              {getString("ordering.requestSecurityReview")}
            </Button>
          </Col>
        </Row>

        <View padding="16px 0">
          <Text fontSize="T100" data-cy="already-reviewed-text">
            {getString("ordering.unsanctionedMessage")} &nbsp;
            <Link href="#" onClick={orderNowHandler} data-cy="confirm-use-case-link">
              {getString("ordering.confirmUseCase")}
            </Link>
          </Text>
        </View>
      </View>
    );
  }

  if (status == ItemStatus.unavailable) {
    return (
      <View className="order-now">
        <Text data-cy="unavailable-title">{getString("ordering.unavailable")}</Text>
      </View>
    );
  }

  return <div></div>;
};
