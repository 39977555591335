import { DetailedRadio, Select } from "@amzn/stencil-react-components/form";
import { Col, GridContainer, GridItem, Row, Spacer, View } from "@amzn/stencil-react-components/layout";
import { Label } from "@amzn/stencil-react-components/text";
import { getString } from "common/uistringlabels/uiStringUtils";
import React, { FC, useEffect } from "react";

export interface SelectChildSkuProps {
  versionsChildSkus: any[] | undefined;
  onChange: (value: any) => void;
  selectedSku: any;
  useBoldText?: boolean;
}

export const SelectChildSku: FC<SelectChildSkuProps> = ({
  versionsChildSkus,
  onChange,
  selectedSku,
  useBoldText = true,
}: SelectChildSkuProps) => {
  useEffect(() => {
    if (versionsChildSkus && versionsChildSkus.length > 0 && !selectedSku) {
      onChange(versionsChildSkus[0]);
    }
  }, [versionsChildSkus, onChange, selectedSku]);

  /* istanbul ignore next */
  const handleRadioChange = (sku: any) => {
    console.log("handleRadioChange: ", sku);
    onChange(sku);
  };

  /* istanbul ignore next */
  const handleSelectChange = (selectedValue: string) => {
    const newSelectedSku = versionsChildSkus?.find((sku) => sku.shortDescription === selectedValue);
    if (newSelectedSku) {
      onChange(newSelectedSku);
    }
  };

  const renderRadios = () => {
    if (versionsChildSkus) {
      const pairs = [];

      for (let i = 0; i < versionsChildSkus.length; i += 2) {
        const pair = versionsChildSkus.slice(i, i + 2);
        const radioButtons = pair.map((version) => {
          return (
            <Row key={`radio-${i}-${version.id}`} width={420}>
              <DetailedRadio
                dataTestId="radio-child-sku"
                titleText={useBoldText ? version.shortDescription : undefined}
                details={!useBoldText ? version.shortDescription : undefined}
                checked={selectedSku?.id === version.id}
                onChange={() => handleRadioChange(version)}
              />
            </Row>
          );
        });

        pairs.push(
          <Row key={`row-version-${i}`} gridGap={"S300"}>
            {radioButtons}
          </Row>
        );
      }

      return pairs;
    }
  };

  if (versionsChildSkus && versionsChildSkus.length <= 6) {
    return <Col gridGap={"S300"}>{renderRadios()}</Col>;
  } else if (versionsChildSkus && versionsChildSkus.length > 6) {
    const options = versionsChildSkus.map((sku) => sku.shortDescription);
    return (
      <Col gridGap="S200">
        <Select
          dataTestId="select-child-sku"
          onChange={handleSelectChange}
          options={options}
          value={selectedSku?.shortDescription}
          isSearchable={true}
          width="65%"
        />
      </Col>
    );
  } else {
    return null;
  }
};
