import "./orderConfirmation.scss";

import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { IconCheckCircleFill, IconSize } from "@amzn/stencil-react-components/icons";
import { Col, Container, GridContainer, GridItem, Row, View } from "@amzn/stencil-react-components/layout";
import { Text } from "@amzn/stencil-react-components/text";
import { InlineUserReference } from "common/components/InlineUserReference";
import { CatalogItemDomains, CreateOrderResponseContent } from "common/types";
import { getString } from "common/uistringlabels/uiStringUtils";
import React, { FC } from "react";
import { useHistory, useLocation } from "react-router-dom";

export interface OrderConfirmationState extends CreateOrderResponseContent {
  canBeAutoApproved?: boolean;
}

const OrderConfirmation: FC = () => {
  const location = useLocation();
  const history = useHistory();

  const localState: OrderConfirmationState | undefined = location.state as CreateOrderResponseContent;
  if (localState === undefined || localState.orderReference === undefined) {
    history.push("/");
    return null;
  }

  const orderReference = localState.orderReference;

  const onViewOrderClick = () => {
    history.push("/orders");
  };

  const onReturnHomeClick = () => {
    history.push("/");
  };

  const getOrderConfirmationDescription = () => {
    if (localState.itemType && localState.itemType === CatalogItemDomains.HARDWARE) {
      return getString("orderConfirmation.confirmationDescription");
    }

    if (localState.canBeAutoApproved) {
      return getString("orderConfirmation.autoApprovedOrder.instructions");
    }

    const approverReferenceComponent = <InlineUserReference userReference={localState.requiredApprover!} />;

    return (
      <>
        {`${getString("orderConfirmation.managerRequest.instructions.part1")} `} {approverReferenceComponent}{" "}
        {`${getString("orderConfirmation.managerRequest.instructions.part2")}`}
      </>
    );
  };

  return (
    <Container isFullWidth={true} paddingHorizontal="80px" paddingTop="100px">
      <GridContainer>
        <GridItem lg={2} />
        <GridItem lg={2}>
          {/* Illustration */}
          <View display="flex" justifyContent="center" width="100%" height="100%">
            <div className="earth-icon" />
          </View>
        </GridItem>
        <GridItem lg={6}>
          <Col gridGap="S500">
            {/* Icon & title */}
            <Row gridGap="S300">
              <IconCheckCircleFill
                color="green70"
                size={IconSize.Large}
                title={getString("orderConfirmation.success")}
              />
              <Text fontSize="T300" fontWeight="bold">
                {getString("orderConfirmation.confirmationTitle")}
              </Text>
            </Row>

            {/* Order Id & description */}
            <Col gridGap="S300">
              <Text fontSize="T200" fontWeight="bold">
                {`${getString("orderConfirmation.orderId")}${orderReference}`}
              </Text>
              <Text fontSize="T200" fontWeight="regular">
                {getOrderConfirmationDescription()}
              </Text>
            </Col>

            {/* Buttons */}
            <Row gridGap="S300">
              <Button data-testid="view-order-button" variant={ButtonVariant.Secondary} onClick={onViewOrderClick}>
                {getString("orderConfirmation.viewOrderButton")}
              </Button>
              <Button data-testid="return-home-button" variant={ButtonVariant.Tertiary} onClick={onReturnHomeClick}>
                {getString("orderConfirmation.returnHomeButton")}
              </Button>
            </Row>
          </Col>
        </GridItem>
        <GridItem lg={2} />
      </GridContainer>
    </Container>
  );
};

export default OrderConfirmation;
