import "./CheckoutPageSHOP.scss";

import { DeliveryAddress, Order } from "@amzn/ito-client";
import { Avatar, AvatarText } from "@amzn/stencil-react-components/avatar";
import { Button, ButtonSize, ButtonVariant } from "@amzn/stencil-react-components/button";
import { Card } from "@amzn/stencil-react-components/card";
import { EmployeeBanner } from "@amzn/stencil-react-components/employee-banner";
import {
  Checkbox,
  FormWrapper,
  Input,
  InputFooter,
  InputHeader,
  InputWrapper,
  Select,
  TextArea,
} from "@amzn/stencil-react-components/form";
import { IconExternalLink, IconSize } from "@amzn/stencil-react-components/icons";
import { Col, Container, Row, View } from "@amzn/stencil-react-components/layout";
import { Link } from "@amzn/stencil-react-components/link";
import { MessageBanner } from "@amzn/stencil-react-components/message-banner";
import { ModalContent, WithModal } from "@amzn/stencil-react-components/modal";
import { Spinner } from "@amzn/stencil-react-components/spinner";
import { H1, Label, Text } from "@amzn/stencil-react-components/text";
import { SelectChildSku } from "common/components/SelectChildSku";
import { CreateOrderRequestContent } from "common/types";
import { IOrdersService, OrdersServiceFactory, UserPermissions } from "lib/services";
import { CSRFService } from "lib/services/csrf-service";
import { AmazonianInformation, UserInformation } from "lib/services/user-information-service";
import React, { FC, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { OrderConfirmationState } from "../OrderConfirmation/OrderConfirmation";
import { useChangeAddressForm } from "./useChangeAddressForm";
import { useCheckoutForm } from "./useCheckoutForm";

export interface CheckoutPageShopProps {}

export interface CatalogItemVersion {
  id?: string;
  price?: number;
  recurrence?: any;
  currency?: string;
  name?: string;
  shortDescription?: string;
}

const mockVersionsChildSkus: CatalogItemVersion[] = [
  {
    id: "atn::hardware/desktop/windows/hp/5fu03uc",
    price: 150,
    currency: "USD",
    name: "HP ProDesk 600 Mini",
    shortDescription: "i5 - 6 cores, 14 inch",
  },
  {
    id: "atn::hardware/desktop/windows/hp/5fu03uc2",
    price: 300,
    currency: "USD",
    name: "HP ProDesk 600 Mini",
    shortDescription: "i7 - 6 cores, 14 inch",
  },
  {
    id: "atn::hardware/desktop/windows/hp/5fu03uc3",
    price: 350,
    currency: "USD",
    name: "HP ProDesk 600 Mini",
    shortDescription: "i7 - 12 cores, 14 inch",
  },
  {
    id: "atn::hardware/desktop/windows/hp/5fu03uc4",
    price: 450,
    currency: "USD",
    name: "HP ProDesk 600 Mini",
    shortDescription: "i7 - 12 cores, 16 inch",
  },
];

const CheckoutPageSHOP: FC = (props: CheckoutPageShopProps) => {
  const userInfoService = new UserInformation();

  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingSubmitOrder, setIsLoadingSubmitOrder] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<AmazonianInformation | undefined>(undefined);

  const {
    formValues: addressValues,
    handleInputChange: handleAddressChange,
    setAddressInformation,
  } = useChangeAddressForm();
  const {
    formValues: checkoutValues,
    handleInputChange: handleCheckoutChange,
    createDeliveryAddress,
  } = useCheckoutForm(mockVersionsChildSkus[0]);

  const hasRequiredFields =
    checkoutValues.managerConfirmationCheckbox &&
    checkoutValues.shippingDetailsConfirmationCheckbox &&
    checkoutValues.policyConfirmationCheckbox &&
    checkoutValues.costCenter !== "" &&
    checkoutValues.businessJustification !== "";

  useEffect(() => {
    if (!userInfo) {
      getUserInfo();
    }
  }, []);

  const show = (text: string | null | undefined): string => text ?? "";

  const getUserInfo = async () => {
    console.log("getUserInfo()");
    const amazonianEmployeeInfo = await userInfoService.getUserInformation();
    setUserInfo(amazonianEmployeeInfo);
    setAddressInformation(amazonianEmployeeInfo);
    setIsLoading(false);
  };

  const onCancelClick = () => {
    console.log("onCancelClick()");
  };

  // const onSubmitClick = () => {
  //   // Call ITO

  //   console.log("onSubmitClick()");
  // };

  const onSubmitClick = async () => {
    console.log("onSubmitClick()");
    const request: CreateOrderRequestContent = {
      items: {
        [checkoutValues.catalogItemTxId.id!]: { quantity: 1 },
      },
      // deliveryTo: createDeliveryAddress(addressValues),
      // details: {
      //   checkedPolicy: checkoutValues.policyConfirmationCheckbox
      // } as any
    };
    await submitOrderHandler(request);
  };

  const submitOrderHandler = async (request: CreateOrderRequestContent) => {
    const ordersService: IOrdersService = OrdersServiceFactory.getInstance(mockVersionsChildSkus[0].id!);

    try {
      setIsLoadingSubmitOrder(true);
      const token = await new CSRFService().getToken();
      const response = await ordersService.CreateOrder(request, token);
      if (!response.orderId) {
        setIsLoadingSubmitOrder(false);
        throw new Error("There was an error while creating the order");
      }
      // Removes the ito:: part of the id
      const orderTaxonomyId = response.orderId.substring(5);
      const state: OrderConfirmationState = {
        ...response,
      };
      // close();
      history.push({
        pathname: `/confirmation/${orderTaxonomyId}`,
        state,
      });
    } catch (error: any) {
      setIsLoadingSubmitOrder(false);
    }
  };

  const renderChangeVersionModal = ({ close }: { close: () => void }) => {
    return (
      <ModalContent
        maxWidth={null}
        titleText="Change version"
        buttons={[
          <Button key="12" onClick={() => close()}>
            Cancel
          </Button>,
          <Button key="13" onClick={() => close()}>
            Save
          </Button>,
        ]}
      >
        <Col width={847}>
          <SelectChildSku
            useBoldText={false}
            versionsChildSkus={mockVersionsChildSkus}
            selectedSku={checkoutValues.catalogItemTxId} // add useState
            onChange={(event) => handleCheckoutChange("catalogItemTxId", event)}
          />
        </Col>
      </ModalContent>
    );
  };

  const onChangeShippingModalSave = (close: () => void) => {
    console.log("verify the address here...");
    close();
  };

  const renderChangeShippingModal = ({ close }: { close: () => void }) => {
    return (
      <ModalContent
        maxWidth={null}
        titleText="Change shipping details"
        buttons={[
          <Button key="1" onClick={() => close()}>
            Cancel
          </Button>,
          <Button key="2" onClick={() => onChangeShippingModalSave(close)}>
            Save
          </Button>,
        ]}
      >
        <Col width={816} gridGap="S500">
          {/* Required field label */}
          <Label fontSize="T200">* Required field.</Label>
          <Container paddingBottom="S500">
            <Col gridGap={"S200"} width="77%">
              <Col gridGap={"S100"}>
                {/* <Label color="neutral90" fontWeight="regular" fontSize="T200" htmlFor="">Alias *</Label> */}
                <InputHeader labelText="Alias" htmlFor="" required />
                <Input id="" value={UserPermissions.current.username ?? ""} disabled />
              </Col>
              <Col gridGap={"S100"}>
                <InputHeader labelText="Full name" htmlFor="" required />
                <Input
                  id=""
                  value={addressValues.fullName}
                  onChange={(event) => handleAddressChange("fullName", event.target.value)}
                />
              </Col>
              <Col gridGap={"S100"}>
                <InputHeader labelText="Country" htmlFor="select-country" required />
                <Select
                  width="73%"
                  id="select-country"
                  isMulti={false}
                  labelId="some-select-label"
                  onChange={(selectedValue) => handleAddressChange("country", selectedValue)}
                  options={["United States", "Canada", "Costa Rica", "Panama"]}
                  value={addressValues.country}
                />
              </Col>
              <Col gridGap={"S100"}>
                <Row gridGap={"S100"} alignItems="end">
                  <InputHeader labelText="Address line 1" htmlFor="" required />
                  <Label color="neutral70" fontSize="T100">
                    {"(Street address, P.O box, company name, c/o)"}
                  </Label>
                </Row>
                <Input
                  id=""
                  value={addressValues.addressLine1}
                  onChange={(event) => handleAddressChange("addressLine1", event.target.value)}
                />
              </Col>
              <Col gridGap={"S100"}>
                <Row gridGap={"S100"} alignItems="end">
                  <InputHeader labelText="Address line 2" htmlFor="" />
                  <Label color="neutral70" fontSize="T100">
                    {"(Apartment, suite, unit, building, floor, etc)"}
                  </Label>
                </Row>
                <Input
                  id=""
                  value={addressValues.addressLine2}
                  onChange={(event) => handleAddressChange("addressLine2", event.target.value)}
                />
              </Col>
              <Row gridGap={"S300"}>
                <Col gridGap={"S100"} width={301}>
                  <InputHeader labelText="City" htmlFor="" required />
                  <Input
                    id=""
                    value={addressValues.city}
                    onChange={(event) => handleAddressChange("city", event.target.value)}
                  />
                </Col>
                <Col gridGap={"S100"} width="100%">
                  <InputHeader labelText="State/Province/Region" htmlFor="" required />
                  <Select
                    id="some-select"
                    isMulti={false}
                    labelId="some-select-label"
                    onChange={(selectedValue) => handleAddressChange("countryCode", selectedValue)}
                    options={["Heredia", "San José", "Limón", "Alajuela", "Cartago"]}
                    value={addressValues.citySubdivision1}
                  />
                </Col>
              </Row>
              <Col gridGap={"S100"} width={301}>
                <InputHeader labelText="ZIP code/Postal code" htmlFor="" required />
                <Input
                  id=""
                  value={addressValues.postalCode}
                  onChange={(event) => handleAddressChange("postalCode", event.target.value)}
                />
              </Col>
              <Row gridGap={"S300"}>
                <Col gridGap={"S100"} width={150}>
                  <InputHeader labelText="Country code" htmlFor="" required />
                  <Select
                    id="some-select"
                    isMulti={false}
                    labelId="some-select-label"
                    placeholder="code"
                    onChange={(selectedValue) => handleAddressChange("countryCode", selectedValue)}
                    options={["1", "220", "506", "595"]}
                    value={addressValues.countryCode}
                  />
                </Col>
                <Col gridGap={"S100"} width="100%">
                  <InputHeader labelText="Phone number" htmlFor="" required />
                  <Input
                    id=""
                    value={addressValues.phoneNumber}
                    onChange={(event) => handleAddressChange("phoneNumber", event.target.value)}
                  />
                </Col>
              </Row>
            </Col>
          </Container>
        </Col>
      </ModalContent>
    );
  };

  return (
    <Container backgroundColor="" isFullWidth={true}>
      <Row>
        <Col backgroundColor="neutral05" width={367} dataTestId="side-panel">
          <Container paddingHorizontal={40} paddingTop={32} isFullWidth={true}>
            <Col gridGap="S400" width="100%">
              {/* <View display="flex" justifyContent="center" width="100%" height="100%"> */}
              <div className="catalog-image-bg">
                <div className="catalog-image" />
              </div>
              {/* </View> */}
              <Col gridGap="S300">
                <Text color="neutral90" fontSize="T200" fontWeight="bold">
                  {`${show(checkoutValues.catalogItemTxId.name)}`}
                </Text>
                <Col>
                  <Text color="neutral70" fontSize="T200">
                    Price
                  </Text>
                  <Text color="neutral90" fontSize="T200" fontWeight="bold">
                    {`${show(checkoutValues.catalogItemTxId.currency)} $${show(
                      checkoutValues.catalogItemTxId.price?.toString()
                    )}`}
                  </Text>
                </Col>
                <Col>
                  <Text color="neutral70" fontSize="T200">
                    Version
                  </Text>
                  <Text color="neutral90" fontSize="T200" fontWeight="bold">
                    {`${show(checkoutValues.catalogItemTxId.shortDescription)}`}
                  </Text>
                </Col>
                <Row>
                  <WithModal renderModal={renderChangeVersionModal} isScrollable={false}>
                    {({ open }) => (
                      <Button size={ButtonSize.Small} variant={ButtonVariant.Tertiary} onClick={open}>
                        Change version
                      </Button>
                    )}
                  </WithModal>
                </Row>
              </Col>
            </Col>
          </Container>
        </Col>
        <Col backgroundColor="" width="100%">
          <Container backgroundColor="" paddingHorizontal={80} paddingTop={32} paddingBottom={32} isFullWidth={true}>
            <Col backgroundColor="" gridGap="S400" width="100%">
              {/* Title */}
              <Text fontSize="T500" fontWeight="bold">
                Checkout
              </Text>

              {/* Banner */}
              <MessageBanner isDismissible={true}>
                {
                  "You're about to place an upgrade order that will be billed to your cost center. Manager approval is required to create the Purchase order."
                }
              </MessageBanner>

              <Text fontSize="T200">* Required field.</Text>

              {/* Step 1 - Get approval information */}
              <Card padding="S300" width="100%">
                <Col gridGap="S300" width="100%">
                  {/* Title */}
                  <Col gridGap="S300">
                    <Text fontSize="T400" fontWeight="bold">
                      Step 1: Get approval information
                    </Text>
                    <Text fontSize="T200">
                      After your order is submitted, your manager will receive an approval request email from Coupa or
                      SCos
                    </Text>
                  </Col>

                  {/* Manager info */}
                  {isLoading && <Spinner />}
                  {!isLoading && (
                    <Col gridGap="S300">
                      <Text fontSize="T300" fontWeight="bold">
                        Your manager
                      </Text>
                      <Avatar
                        fullName={`${show(userInfo?.manager.basicInfo.firstName)} ${show(
                          userInfo?.manager.basicInfo.lastName
                        )}`}
                        username={`${show(userInfo?.manager.username)}`}
                        profileImageUrl={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${userInfo?.manager.username}`}
                      >
                        <AvatarText>{`L${show(userInfo?.manager.jobInfo.jobLevel)}, ${show(
                          userInfo?.manager.jobInfo.businessTitle
                        )}`}</AvatarText>
                      </Avatar>
                    </Col>
                  )}

                  {/* Business justification */}
                  <Col gridGap="S200" width="85%">
                    <Label>Business justification *</Label>
                    <TextArea
                      resize="none"
                      placeholder="Example: I need a laptop with a better processor to run a large amount of queries"
                      value={checkoutValues.businessJustification}
                      onChange={(event) => handleCheckoutChange("businessJustification", event.target.value)}
                    />
                  </Col>

                  {/* Cost center */}
                  <Col gridGap="S200" width="85%">
                    <Label>Cost center *</Label>
                    <Input
                      placeholder="Example: 12345"
                      value={checkoutValues.costCenter}
                      onChange={(event) => handleCheckoutChange("costCenter", event.target.value)}
                    />
                    <InputFooter>
                      Ask your manager or your finance department for your 4-5 digit cost center number.
                    </InputFooter>
                  </Col>

                  {/* Checkbox */}
                  <Row gridGap="S200">
                    <Checkbox
                      checked={checkoutValues.managerConfirmationCheckbox}
                      onChange={(event) => handleCheckoutChange("managerConfirmationCheckbox", event.target.checked)}
                    />
                    <Label>
                      I confirm that my manager is aware that this equipment will be billed to my cost center, and that
                      the information provided is accurate. *
                    </Label>
                  </Row>
                </Col>
              </Card>

              {/* Step 2 - Confirm shipping details */}
              <Card padding="S300" width="100%">
                <Col gridGap="S300" width="100%">
                  <Col>
                    <Text fontSize="T400" fontWeight="bold">
                      Step 2: Confirm shipping details
                    </Text>
                    <Text fontSize="T100">
                      We pre-populated your information from our database. Confirm shipping details before submitting
                      your order.
                    </Text>
                  </Col>

                  <Col width="85%" gridGap="S100">
                    <Card padding="S300">
                      {isLoading && <Spinner />}
                      {!isLoading && (
                        <Col gridGap="S300">
                          <Col gridGap="S200">
                            <Text fontSize="T300" fontWeight="bold">
                              {`${userInfo?.basicInfo.firstName} ${userInfo?.basicInfo.lastName}`}
                            </Text>
                            <Text>{`${show(addressValues.addressLine1)} ${show(addressValues.city)} ${show(
                              addressValues.citySubdivision1
                            )} ${show(addressValues.country)}, ${show(addressValues.postalCode)}`}</Text>
                            {addressValues.countryCode !== "" && (
                              <Text>{`+${show(addressValues.countryCode)} ${show(addressValues.phoneNumber)}`}</Text>
                            )}
                          </Col>
                          <Row>
                            <WithModal renderModal={renderChangeShippingModal}>
                              {({ open }) => (
                                <Button size={ButtonSize.Small} onClick={open}>
                                  Change shipping details
                                </Button>
                              )}
                            </WithModal>
                          </Row>
                        </Col>
                      )}
                    </Card>
                    <MessageBanner>
                      Please be aware that hardware deliveries require a signature. Make sure someone will be able to
                      receive the package.
                    </MessageBanner>
                  </Col>

                  <Col gridGap="S200" width="85%">
                    <Label>Delivery instructions</Label>
                    <TextArea resize="none" placeholder="Example: Leave in back door" />
                  </Col>

                  <Row gridGap="S200">
                    <Checkbox
                      checked={checkoutValues.shippingDetailsConfirmationCheckbox}
                      onChange={(event) =>
                        handleCheckoutChange("shippingDetailsConfirmationCheckbox", event.target.checked)
                      }
                    />
                    <Label>I confirm the shipping details are accurate.*</Label>
                  </Row>
                </Col>
              </Card>

              {/* Policy confirmation */}
              <Row gridGap="S200">
                <Checkbox
                  checked={checkoutValues.policyConfirmationCheckbox}
                  onChange={(event) => handleCheckoutChange("policyConfirmationCheckbox", event.target.checked)}
                />
                <Text>
                  I confirm I have read the conditions of the{" "}
                  <Link href="" icon={<IconExternalLink title="Opens in a new tab" />} target="_blank">
                    Hardware Ownsership Policy
                  </Link>{" "}
                  and{" "}
                  <Link href="" icon={<IconExternalLink title="Opens in a new tab" />} target="_blank">
                    IT Services Hardware Policy
                  </Link>
                  . *
                </Text>
              </Row>

              {/* Buttons */}
              <Row gridGap="S300" display="flex" justifyContent="flex-end">
                <Button dataTestId="cancel-order-button" variant={ButtonVariant.Tertiary} onClick={onCancelClick}>
                  Cancel
                </Button>
                <Button
                  dataTestId="submit-order-button"
                  disabled={!hasRequiredFields || isLoadingSubmitOrder}
                  variant={ButtonVariant.Primary}
                  onClick={onSubmitClick}
                >
                  Submit order
                  {isLoadingSubmitOrder ? <Spinner /> : undefined}
                </Button>
              </Row>
            </Col>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default CheckoutPageSHOP;
