import "./style.scss";

import { Col, Spacer } from "@amzn/stencil-react-components/layout";
import { PageHeader, PageHeaderColorScheme, PageHeaderLink } from "@amzn/stencil-react-components/page";
import { trackPageView } from "@snowplow/browser-tracker";
import SearchBoxFactory from "common/factories/search-box-factory";
import { getString } from "common/uistringlabels/uiStringUtils";
import { OrdersServiceFactory, UserPermissions } from "lib/services";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { ITMP_V1_URL, ITMP_V2_ADMIN_URL, PERMISSIONS } from "../../../config";
import { ApprovalRequestsLink } from "../ApprovalRequestsLink";
import UserProfile from "../UserProfile";

export const Header = () => {
  const location = useLocation();
  const [isSearchbarVisible, setSearchbarVisible] = useState(false);

  const useQuery = () => {
    return new URLSearchParams(location?.search);
  };
  const queryValue = useQuery().get("query");

  const isHomePath = (): boolean => {
    return location?.pathname === "/";
  };

  const toggleSearchBar = (): void => {
    setSearchbarVisible(!isHomePath());
  };

  /* istanbul ignore next */
  useEffect(() => {
    toggleSearchBar();
    if (location?.pathname) {
      trackPageView();
    }
  }, [location?.pathname]);

  const history = useHistory();

  /* istanbul ignore next */
  const onLogoClickHander = (e: any) => {
    e.preventDefault();
    history.push("/");
  };

  const isITOForOrders: boolean = OrdersServiceFactory.isITO();

  return (
    <PageHeader colorScheme={PageHeaderColorScheme.Inverted} hasShadow={true} isFixed={true} isFullWidth={false}>
      <nav aria-label="Site" className="site-navigation">
        <PageHeaderLink href="/" hasHover={false} data-cy="logo-link" className="logo-link" onClick={onLogoClickHander}>
          {getString("logoText")}
        </PageHeaderLink>
        <Spacer flex={1} />
        {isSearchbarVisible && (
          <Col width="30%" alignItems="center" className="search-box">
            <SearchBoxFactory queryText={queryValue}></SearchBoxFactory>
          </Col>
        )}
        <Spacer flex={1} />

        {isITOForOrders && (
          <PageHeaderLink
            key="1"
            href="/orders"
            /* istanbul ignore next */
            onClick={(e) => {
              e.preventDefault();
              history.push("/orders");
            }}
            data-cy="orders-link"
            className="header-link orders-link"
          >
            {getString("header.orders")}
          </PageHeaderLink>
        )}

        {!isITOForOrders && (
          <PageHeaderLink
            key="1"
            href={`${ITMP_V1_URL}/user_profiles`}
            data-cy="orders-link"
            className="header-link orders-link"
          >
            {getString("header.orders")}
          </PageHeaderLink>
        )}

        {UserPermissions.current.hasPermission(PERMISSIONS.SOFTWARE_PROCUREMENT) && (
          <PageHeaderLink key="2" href={ITMP_V2_ADMIN_URL} data-cy="admin-link" className="header-link admin-link">
            {getString("header.admin")}
          </PageHeaderLink>
        )}
        <ApprovalRequestsLink></ApprovalRequestsLink>
        <PageHeaderLink
          key="4"
          aria-label={getString("header.helpAriaLabel")}
          data-cy="help-link"
          href="https://w.amazon.com/bin/view/ITMarketplaceSoftwareOrdering"
          className="header-link icon help"
        />
        <UserProfile username={UserPermissions.current.username}></UserProfile>
      </nav>
    </PageHeader>
  );
};
