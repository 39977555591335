import { IconAlertTriangleFill, IconCheckCircleFill, IconSize } from "@amzn/stencil-react-components/icons";
import { Row } from "@amzn/stencil-react-components/layout";
import { Status, StatusIndicator, StatusIndicatorSize } from "@amzn/stencil-react-components/status-indicator";
import { getString } from "common/uistringlabels/uiStringUtils";
import React, { FunctionComponent } from "react";

export interface EtaStatusIndicatorProps {
  etaDays: number;
  hasStock: boolean;
  size: IconSize;
}

export const EtaStatusIndicator: FunctionComponent<EtaStatusIndicatorProps> = ({ etaDays, hasStock, size }) => {
  if (hasStock === true) {
    return (
      <Row gridGap="S300">
        <IconCheckCircleFill color="green70" size={size} />
        <strong>{getString("eta.statusIndicator.available")}</strong>
      </Row>
    );
  }
  const daysRange = `${etaDays}-${etaDays + 2}`;
  return (
    <Row gridGap="S300">
      <strong>{getString("eta.statusIndicator.wait", { daysRange })}</strong>
    </Row>
  );
};
