import { API_BASE_URL } from "config";
import { StatusCodes } from "http-status-codes";

import { BaseService } from "./base-service";

export interface BasicInfo {
  emailAddress: string;
  firstName: string;
  lastName: string;
  phones: Phone[] | null;
  addresses: Addresses;
}

export interface JobInfo {
  businessTitle: string;
  jobLevel: string;
}

export interface Phone {
  countryCode: string;
  extension: string;
  number: string;
  preferred: string;
  type: string;
}

export interface Address {
  addressType: string | null;
  addressLineData: string[]; // # one or more strings containing the address lines
  localAddressLineData: string[]; // # one or more strings containing the local address lines
  city: string | null;
  citySubdivision1: string | null;
  citySubdivision2: string | null;
  region: string | null;
  regionSubdivision1: string | null;
  regionSubdivision2: string | null;
  country: string | null;
  postalCode: string | null;
}

export interface Addresses {
  home: Address;
}

export interface AmazonianInformation {
  basicInfo: BasicInfo;
  manager: { basicInfo: BasicInfo; jobInfo: JobInfo; username: string };
}

/**
 * Implements user Validation, Authorization and Authentication.
 */
export class UserInformation extends BaseService {
  constructor() {
    super(API_BASE_URL);
  }

  public async getUserInformation(): Promise<any> {
    const response = await this.fetch("/user-info");
    if (response.status !== StatusCodes.OK) {
      throw new Error("An error occurred getting the user information.");
    }
    const jsonResponse = await response.json();
    console.log("Response: ", jsonResponse);
    return jsonResponse as string;
  }
}
